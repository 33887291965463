import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import {Link} from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "underscore";
import {
  Container, Row, Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormRadio,
  FormGroup, FormSelect, FormInput, Button, FormCheckbox, FormTextarea, InputGroup, InputGroupText, InputGroupAddon
} from "shards-react";
//https://designrevision.com/docs/shards-react/getting-started
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {Common as util} from "../../../utils/common";


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


let initSetting = {
  writer: '',
  password: '',
  isAutoWrite: false,
  isShowWriterName:  false,
  isShowProductName: false,
  sentencesNum: '2~3',
  autoReplyStartTime: 9,
  autoReplyEndTime: 18,
  autoReplyFrequency: 3,
  autoRunTimes: [],
  atmosphere: ['', '', ''],
  reviewRatingConfigs: [
    { isAutoWrite: true, rating: '1_20', type: 'na', isShowWriterName:false, isShowProductName: false, tone: ''},
    { isAutoWrite: true, rating: '21_40', type: 'fixed', isShowWriterName:false, isShowProductName: false, tone: '안녕하세요. [구매자명]님. [상품명]리뷰 작성 감사합니다. \n' +
          '[상품별 추가 내용]' +
          '고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
    { isAutoWrite: true, rating: '41_100', type: 'ai', isShowWriterName:true, isShowProductName: true, tone: '안녕하세요. 고객님. 리뷰 작성 감사합니다. 고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
  ]
}

let initNewReviewRatingConfigs = {
  isAutoWrite: false,
  isShowWriterName:  false,
  isShowProductName: false,
  rating: '',
  type: 'ai',
  tone: ''
}

let atmosphere_ex = "고객님의 만족스러운 리뷰에 감사드립니다. 앞으로도 더 좋은 제품으로 보답하겠습니다.";



@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc, getCollectionCount: common.getCollectionCount }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, checkOrder:teamUser.checkOrder }))
@inject(({ company }) => ({ updateStore:company.updateStore, getApplySkins:company.getApplySkins, applyAllSkin:company.applyAllSkin, applySkin:company.applySkin}))
@inject(({ review }) => ({ setReviewSync: review.setReviewSync, cloudFlareMng:review.cloudFlareMng }))

@inject(({ AOA }) => ({ initReviewData: AOA.initReviewData }))

@observer
class ReplyWriteConfig extends Component {

  //basic
  @observable teamUser = {};
  @observable permissions = {}
  @observable store = {};
  @observable company = {};
  @observable isLogin = false;

  //page
  @observable setting = initSetting;
  @observable newReviewRatingConfigs = initNewReviewRatingConfigs;
  @observable generateTimePeriodsStr = '';
  @observable skins = [];

  //modals
  @observable modals = {
    cafeSettingData: {},
    isCafeSettingModal: false
  };

  constructor(props) {
    super(props);
    this.init();
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.teamUser.store;
      // console.log(this.store, this.store.useExpiryDate, this.store.isUseFirstOrder, this.store.isPaidUser, this.store.orderName);
      // this.comp1any = this.props.me.company ? this.props.me.company : {};
      this.permissions = this.props.me.permissions;
    }
    let res = await this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id, shopNo: this.teamUser.selShopNo ? this.teamUser.selShopNo : 1});
    let storeBasicSettings = _.defaults(res.basicSettings, initSetting);

    storeBasicSettings.writer = storeBasicSettings.writer !== '' ? storeBasicSettings.writer : this.store.name;
    storeBasicSettings.store_id = storeBasicSettings.store_id ? storeBasicSettings.store_id : this.store._id;

    if(!this.store.isPaidUser) {
      storeBasicSettings.isAutoWrite = false;
      for(let i = 0; i < storeBasicSettings.reviewRatingConfigs.length; i++) {
        storeBasicSettings.reviewRatingConfigs[i].isAutoWrite = false;
      }
      const res = await this.props.updateDoc('updateBasicSettings', storeBasicSettings);
    }
    else if(moment().isAfter(this.store.useExpiryDate)) {
      let updatedStore = await this.props.checkOrder({store_id: this.store._id});
      this.store.isPaidUser = false;
    }
    this.setting = storeBasicSettings;
    //this.setting.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo : 1

    initNewReviewRatingConfigs.isAutoWrite = this.setting.isAutoWrite;
    initNewReviewRatingConfigs.isShowWriterName = this.setting.isShowWriterName;
    initNewReviewRatingConfigs.isShowProductName = this.setting.isShowProductName;
    this.generateTimePeriods();
    this.getApplySkins(false);
  };

  @action
  onChangeInputs = (event, i) => {
    const { name, value } = event.target;
    if(name === 'autoReplyStartTime' || name === 'autoReplyEndTime' || name === 'autoReplyFrequency') {
      this.setting[name] = parseInt(value);
      this.generateTimePeriods();
    }
    else if( name === 'atmosphere') {
      this.setting.atmosphere[i] = value;
    }
    else {
      this.setting[name] = value;
    }

  };

  @action
  addRatingConfigs = async () => {
    let _this = this;
    if(this.newReviewRatingConfigs.rating === '') {
      toast('별점을 입력해 주세요.');
      return;
    }
    this.setting.reviewRatingConfigs.push(this.newReviewRatingConfigs);
    this.newReviewRatingConfigs = initNewReviewRatingConfigs;
    this.newReviewRatingConfigs.isAutoWrite = this.setting.isAutoWrite;
    this.newReviewRatingConfigs.isShowWriterName = this.setting.isShowWriterName;
    this.newReviewRatingConfigs.isShowProductName = this.setting.isShowProductName;
  }

  @action
  saveAutoReplyConfig = async () => {
    if(this.setting.writer === '') {
      toast('답글 작성에 필요한 답글 작성자 이름을 입력해 주세요.');
      return;
    }
    else if(this.setting.password === '') {
      alert('답글 작성에 필요한 비밀번호를 입력해 주세요.');
      return;
    }


    let updateSettings = util.getDataStByKey(this.setting, ["store_id", "shopNo", "writer", "password", "isAutoWrite", "isShowWriterName", "isShowProductName", "sentencesNum",
    "autoReplyStartTime", "autoReplyEndTime", "autoReplyFrequency", "autoRunTimes", "atmosphere", "reviewRatingConfigs" ]);

    const res = await this.props.updateDoc('updateBasicSettings', updateSettings);
    if (res.errors && res.errors.length > 0) {
      toast(res.errors[0].message);
    } else {
      toast('저장 되었습니다.');
    }
  };

  @action
  generateTimePeriods = async () => {
    let start = this.setting.autoReplyStartTime;
    let end = this.setting.autoReplyEndTime;
    let interval = this.setting.autoReplyFrequency;
    let times = [];
    let noticeStr = '';
    this.setting.autoRunTimes = [];
    for (let i = start; i <= end; i += interval) {
      const period = i < 12 ? 'am' : 'pm';
      const displayHour = i <= 12 ? i : i - 12;
      times.push(`${period} ${displayHour}`);
      this.setting.autoRunTimes.push(i)
    }
    this.generateTimePeriodsStr = '* ' + times.join('시, ') + ' 답글이 자동 작성됩니다.'
    return noticeStr;
  };

  @action
  getApplySkins = async (actSync) => {
    let _this = this;
    let updateStore = {
      store_id: this.teamUser.store._id,
      actSync: actSync
    }
    this.props.getApplySkins( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.skins = res.data;
        if(actSync) {
          toast('업데이트가 완료 되었습니다.');
        }
      }
    });
  }

  @action
  modalToggle = function(target, data) {
    this.modals[target] = !this.modals[target];
    if(!target) {
      for( var i in this.modals) {
        this.modals[i] = false;
      }
    }

    if(target === 'isCafeSettingModal') {
      if(this.skins.length === 0) {
        this.getApplySkins(true);
      }
    }

    if(data) {
      if(target === 'isCafeSettingModal' && data) {
        console.log(data);
        this.modals.widgetData.product = data;
      }
      else {
        this.modals.data = data;
      }
    }
  };

  onChangeCheckedforApplySkin = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
      if(keyArray[0] === 'isSetScript') {
        targetObj.id = targetObj._id;
        this.props.applySkin(targetObj, function(res) {
          if(res.errors && res.errors.length > 0) {
            alert(res.errors[0].message);
            targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
          else {
            console.log(res)
            toast('처리 되었습니다.');
            //targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
        });
      }
    }
  };



  render() {
    function skinLanguageCodeKR (code) {
      switch (code) {
        case 'ko_KR':
          return '국문';
        case 'en_US':
          return '영문';
        case 'zh_CN':
          return '중문(간체)';
        case 'zh_TW':
          return '중문(번체)';
        case 'ja_JP':
          return '일문';
        case 'pt_PT':
          return '포르투갈어';
        case 'es_ES':
          return '스페인어';
        case 'vi_VN':
          return '베트남어';
        default:
          return code;

      }
    }
    return (
      <>
      <div className="main-content">
          <PageTitle />

          {( this.isLogin && this.teamUser.emailId ) ?
              <div className="write-post-box page-reply-control">
                <div className="section-box">
                  <div className="substitute-table">
                    <div className="section-tit">
                      <h3>답글 작성자 표시명 / 비밀번호</h3>
                    </div>
                    <Row className="mb-4">
                      <Col md="6" id="reviewWriteIn_item1">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="writer">작성자 표시명 <span className="required-indicator">*</span></label>
                              <FormInput name="writer" placeholder="관리자 이름 입력" value={this.setting.writer}
                                         onChange={this.onChangeInputs} className="table-input-text search-input"/>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="password">비밀번호 (작성된 답글 삭제 시 필요합니다.) <span
                                  className="required-indicator">*</span></label>
                              <FormInput name="password" type="password" placeholder="비밀번호 입력"
                                         value={this.setting.password} onChange={this.onChangeInputs}
                                         className="table-input-text search-input"/>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/*<div className="section-tit">*/}
                    {/*  <h3>답글 자동</h3>*/}
                    {/*  <p>ⓘ 지정하신 시간에 맞춰 신규 리뷰를 체크하여 자동으로 답변을 작성합니다.</p>*/}
                    {/*</div>*/}
                    {/*<Row className="mb-4">*/}
                    {/*  <Col md="6">*/}
                    {/*    <Row>*/}
                    {/*      <Col md="12" id="reviewWriteIn_item2">*/}
                    {/*        <FormGroup>*/}
                    {/*          <FormCheckbox toggle checked={this.setting.isAutoWrite} disabled={!this.store.isPaidUser}*/}
                    {/*                        onChange={event => {*/}
                    {/*                          if (this.store.isPaidUser) {*/}
                    {/*                            this.setting.isAutoWrite = !this.setting.isAutoWrite;*/}
                    {/*                          } else {*/}
                    {/*                            this.setting.isAutoWrite = false;*/}
                    {/*                          }*/}
                    {/*                        }}>*/}
                    {/*            자동 작성 {this.setting.isAutoWrite ? '사용 중' : '미사용 중'}*/}
                    {/*          </FormCheckbox>*/}
                    {/*        </FormGroup>*/}
                    {/*      </Col>*/}
                    {/*    </Row>*/}
                    {/*    {this.setting.isAutoWrite ? <>*/}
                    {/*      <Row>*/}
                    {/*        <Col md="4">*/}
                    {/*          <FormGroup>*/}
                    {/*            <label htmlFor="autoReplyStartTime">시작 시간</label>&nbsp;&nbsp;&nbsp;*/}
                    {/*            <FormSelect id="autoReplyStartTime" name="autoReplyStartTime"*/}
                    {/*                        value={this.setting.autoReplyStartTime} onChange={this.onChangeInputs}>*/}
                    {/*              {Array.from({length: 24}, (_, i) => i).map(hour => {*/}
                    {/*                const period = hour < 12 ? 'AM' : 'PM';*/}
                    {/*                const displayHour = hour <= 12 ? hour : hour - 12;*/}
                    {/*                if (hour >= 8 && hour <= 22) {*/}
                    {/*                  return <option key={hour} value={hour}>{`${period} ${displayHour}시`}</option>*/}
                    {/*                }*/}
                    {/*              })}*/}
                    {/*            </FormSelect>*/}
                    {/*          </FormGroup>*/}
                    {/*        </Col>*/}
                    {/*        <Col md="4">*/}
                    {/*          <FormGroup>*/}
                    {/*            <label htmlFor="autoReplyEndTime">죵료 시간</label>*/}
                    {/*            <FormSelect id="autoReplyEndTime" name="autoReplyEndTime"*/}
                    {/*                        value={this.setting.autoReplyEndTime} onChange={this.onChangeInputs}>*/}
                    {/*              {Array.from({length: 24}, (_, i) => i).map(hour => {*/}
                    {/*                const period = hour < 12 ? 'AM' : 'PM';*/}
                    {/*                const displayHour = hour <= 12 ? hour : hour - 12;*/}
                    {/*                if (hour >= 8 && hour <= 22) {*/}
                    {/*                  return <option key={hour} value={hour}>{`${period} ${displayHour}시`}</option>*/}
                    {/*                }*/}
                    {/*              })}*/}
                    {/*            </FormSelect>*/}
                    {/*          </FormGroup>*/}
                    {/*        </Col>*/}
                    {/*      </Row>*/}
                    {/*      <Row>*/}
                    {/*        <Col md="11">*/}
                    {/*          <FormGroup id="reviewWriteIn_item3">*/}
                    {/*            <FormSelect id="autoReplyFrequency" name="autoReplyFrequency"*/}
                    {/*                        value={this.setting.autoReplyFrequency} onChange={this.onChangeInputs}>*/}
                    {/*              /!* todo 구독 가격정첵에 따라 선택 가능 *!/*/}
                    {/*              /!*<option value={1}>1시간 마다</option>*!/*/}
                    {/*              <option value={3}>3시간 마다</option>*/}
                    {/*              <option value={6}>6시간 마다</option>*/}
                    {/*            </FormSelect>*/}
                    {/*            <label htmlFor="autoReplyStartTime"*/}
                    {/*                   className="info-msg">{this.generateTimePeriodsStr}</label>*/}
                    {/*          </FormGroup>*/}
                    {/*        </Col>*/}
                    {/*      </Row>*/}
                    {/*    </> : null}*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                    {/*<div className="section-tit">*/}
                    {/*  <h3>답글 기본 설정</h3>*/}
                    {/*</div>*/}
                    {/*<Row className="mb-4" >*/}
                    {/*  <Col md="6">*/}
                    {/*    <Row id="reviewWriteIn_item4">*/}
                    {/*      <Col md="3"  >*/}
                    {/*        <FormGroup>*/}
                    {/*          <FormCheckbox toggle checked={this.setting.isShowWriterName}*/}
                    {/*                        onChange={event => {*/}
                    {/*                          this.setting.isShowWriterName = !this.setting.isShowWriterName;*/}
                    {/*                        }}>*/}
                    {/*            작성자 {this.setting.isShowWriterName ? '포함' : '미포함'}*/}
                    {/*          </FormCheckbox>*/}
                    {/*        </FormGroup>*/}
                    {/*      </Col>*/}
                    {/*      <Col md="3">*/}
                    {/*        <FormGroup>*/}
                    {/*          <FormCheckbox toggle checked={this.setting.isShowProductName}*/}
                    {/*                        onChange={event => {*/}
                    {/*                          this.setting.isShowProductName = !this.setting.isShowProductName;*/}
                    {/*                        }}>*/}
                    {/*            상품명 {this.setting.isShowProductName ? '포함' : '미포함'}*/}
                    {/*          </FormCheckbox>*/}
                    {/*        </FormGroup>*/}
                    {/*      </Col>*/}
                    {/*      <Col md="6">*/}
                    {/*        <FormGroup>*/}
                    {/*          /!*<label htmlFor="sentencesNum">문장 수</label>&nbsp;&nbsp;&nbsp;*!/*/}
                    {/*          <FormSelect id="sentencesNum" name="sentencesNum"*/}
                    {/*                      value={this.setting.sentencesNum} onChange={this.onChangeInputs}>*/}
                    {/*            /!* todo 구독 가격정첵에 따라 선택 가능 *!/*/}
                    {/*            <option value={"2~3"}>2~3문장</option>*/}
                    {/*            <option value={"3~5"}>3~5문장</option>*/}
                    {/*            <option value={"5~7"}>5~7문장</option>*/}
                    {/*            {(this.store.orderName === "R-1000" || this.store.orderName === "R-2000" || this.store.orderName === "R-5000") ?*/}
                    {/*            <option value={">10"}>10문장 이내</option>*/}
                    {/*            : null}*/}
                    {/*          </FormSelect>*/}
                    {/*        </FormGroup>*/}
                    {/*      </Col>*/}
                    {/*    </Row>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                    <div className="section-tit">
                      <h3>답글 학습</h3>
                    </div>
                    <Row>
                      <Col md="6">
                        <Row className="mb-4">
                          <Col md="11" id="reviewWriteIn_item5">
                            <FormGroup>
                              <FormTextarea name="atmosphere"
                                            value={this.setting.atmosphere[0]}
                                            onChange={event => {
                                              this.onChangeInputs(event, 0)
                                            }}
                                            style={{height: '50px', margin: '0 0 10px 0'}}
                                            placeholder={"자주 사용한 답글 1(ex: " + atmosphere_ex}/>
                              <FormTextarea name="atmosphere"
                                            disabled={!this.setting.atmosphere[0] || this.setting.atmosphere[0] == ''}
                                            value={this.setting.atmosphere[1]}
                                            onChange={event => {
                                              this.onChangeInputs(event, 1)
                                            }}
                                            style={{height: '50px', margin: '0 0 10px 0'}}
                                            placeholder="자주 사용한 답글 2"/>
                              <FormTextarea name="atmosphere"
                                            disabled={!this.setting.atmosphere[0] || this.setting.atmosphere[0] == '' || !this.setting.atmosphere[1] || this.setting.atmosphere[1] == ''}
                                            value={this.setting.atmosphere[2]}
                                            onChange={event => {
                                              this.onChangeInputs(event, 2)
                                            }}
                                            style={{height: '50px', margin: '0 0 10px 0'}}
                                            placeholder="자주 사용한 답글 3"/>
                              <p className="info-msg">*자주 작성했던 답글을 최대 3개 입력해 주세요.</p>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div id="reviewRatingConfigs" className="section-tit">
                      <h3>리뷰별점 답글마법사</h3>
                      <p>ⓘ 고정 답글 설정 시 '[구매자명]', '[상품명]', <Link to={"/reviewByProductList"}>'[상품별 추가 내용]'</Link>으로 입력하면, 이 부분은 자동으로 해당 정보로 변경됩니다."</p>
                    </div>
                    <Row className="mb-5">
                      <Col md="12" id="reviewWriteIn_item6">
                        <Row>
                          <Col md="12">
                            <table>
                              <colgroup>
                                {/*<col width="70px"/>*/}
                                <col width="130px"/>
                                <col width="150px"/>
                                <col width=""/>
                                <col width="200px"/>
                                <col width="130px"/>
                              </colgroup>
                              <thead>
                              <tr>
                                {/*<th>자동 답글</th>*/}
                                <th>별점</th>
                                <th>AI / 고정 답글</th>
                                <th>학습답글 / 고정답글</th>
                                <th>답글 포함 내용</th>
                                <th>추가/삭제</th>
                              </tr>
                              </thead>
                              <tbody>
                              {this.setting.reviewRatingConfigs && this.setting.reviewRatingConfigs.length > 0 ?
                                  this.setting.reviewRatingConfigs.map((item, i) => {
                                        return (<tr key={i}>
                                          {/*<td className='text-center'>*/}
                                          {/*  <fieldset>*/}
                                          {/*    <FormCheckbox toggle*/}
                                          {/*                  checked={item.isAutoWrite}*/}
                                          {/*                  disabled={!this.store.isPaidUser}*/}
                                          {/*                  onChange={event => {*/}
                                          {/*                    item.isAutoWrite = !item.isAutoWrite;*/}
                                          {/*                  }}*/}
                                          {/*                  className="form-check-padding-0"*/}
                                          {/*    ></FormCheckbox>*/}
                                          {/*  </fieldset>*/}
                                          {/*</td>*/}
                                          <td>
                                            <div className="rating-select">
                                              <FormSelect name="rating"
                                                          value={item.rating}
                                                          onChange={event => {
                                                            let isAbleChange = true;
                                                            for (let i = 0; i < this.setting.reviewRatingConfigs.length; i++) {
                                                              let item = this.setting.reviewRatingConfigs[i];
                                                              if (item.rating === event.target.value) {
                                                                toast('이미 선택된 별점입니다.');
                                                                isAbleChange = false;
                                                                break;
                                                              } else if (
                                                                  (event.target.value === '41_100' && (item.rating === '41_60' || item.rating === '61_80' || item.rating === '81_100')) ||
                                                                  (event.target.value === '1_40' && (item.rating === '1_20' || item.rating === '21_40'))
                                                              ) {
                                                                console.log(event.target.value, item.rating);
                                                                toast('별점 범위가 겹칩니다.');
                                                                isAbleChange = false;
                                                                break;
                                                              }
                                                            }
                                                            if (isAbleChange) {
                                                              item.rating = event.target.value;
                                                            }
                                                          }}
                                                          className="">
                                                <option value="">별점 선택</option>
                                                <option value="41_100">3~5점(긍정)</option>
                                                <option value="1_40">1~2점(부정)</option>
                                                <option value="81_100">5점</option>
                                                <option value="61_80">4점</option>
                                                <option value="41_60">3점</option>
                                                <option value="21_40">2점</option>
                                                <option value="1_20">1점</option>
                                              </FormSelect>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="reply-content active">
                                              <FormGroup>
                                                <FormSelect id="type" name="type"
                                                            value={item.type}
                                                            onChange={event => {
                                                              item.type = event.target.value;
                                                            }}
                                                >
                                                  <option value="ai">ai</option>
                                                  <option value="fixed">고정</option>
                                                  <option value="na">미작성</option>
                                                </FormSelect>
                                              </FormGroup>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="reply-content active">
                                              <FormGroup>
                                                <FormTextarea name="tone" value={item.tone}
                                                              onChange={event => {
                                                                item.tone = event.target.value;
                                                              }}
                                                              placeholder={item.type === 'ai' ? '해당 별점을 학습 할 답글예시를 입력해 주세요.' : '해당 별점에 고정 답글 내용을 입력해 주세요.'}/>

                                              </FormGroup>
                                            </div>
                                          </td>
                                          <td>
                                            <Row>
                                              <Col md="12">
                                                <FormCheckbox toggle small
                                                              checked={item.isShowWriterName}
                                                              onChange={event => {
                                                                item.isShowWriterName = !item.isShowWriterName;
                                                              }}>
                                                  리뷰 작성자 {item.isShowWriterName ? '포함' : '미포함'}
                                                </FormCheckbox>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md="12">
                                                <FormCheckbox toggle small
                                                              checked={item.isShowProductName}
                                                              onChange={event => {
                                                                item.isShowProductName = !item.isShowProductName;
                                                              }}>
                                                  상품명 {item.isShowWriterName ? '포함' : '미포함'}
                                                </FormCheckbox>
                                              </Col>
                                            </Row>
                                          </td>
                                          <td>
                                            <Button theme="danger"
                                                    onClick={event => this.addRatingConfigs(event)}><FeatherIcon
                                                icon="minus" size="15" className="nd-icon"/></Button>
                                          </td>
                                        </tr>)
                                      }
                                  )
                                  :
                                  null
                              }
                              <tr style={{border: "3px solid #999999"}}>
                                {/*<td className='text-center'>*/}
                                {/*  <fieldset>*/}
                                {/*    <FormCheckbox toggle*/}
                                {/*                  checked={this.setting.isAutoWrite}*/}
                                {/*                  disabled={!this.store.isPaidUser}*/}
                                {/*                  onChange={event => {*/}
                                {/*                    this.newReviewRatingConfigs.isAutoWrite = !this.newReviewRatingConfigs.isAutoWrite;*/}
                                {/*                  }}*/}
                                {/*                  className="form-check-padding-0"*/}
                                {/*    ></FormCheckbox>*/}
                                {/*  </fieldset>*/}
                                {/*</td>*/}
                                <td>
                                  <div className="rating-select">
                                    <FormSelect name="rating"
                                                value={this.newReviewRatingConfigs.rating}
                                                onChange={event => {
                                                  let isAbleChange = true;
                                                  for (let i = 0; i < this.setting.reviewRatingConfigs.length; i++) {
                                                    let item = this.setting.reviewRatingConfigs[i];
                                                    if (item.rating === event.target.value) {
                                                      toast('이미 선택된 별점입니다.');
                                                      isAbleChange = false;
                                                      break;
                                                    } else if (
                                                        (event.target.value === '41_100' && (item.rating === '41_60' || item.rating === '61_80' || item.rating === '81_100')) ||
                                                        (event.target.value === '1_40' && (item.rating === '1_20' || item.rating === '21_40'))
                                                    ) {
                                                      toast('별점 범위가 겹칩니다.');
                                                      isAbleChange = false;
                                                      break;
                                                    }
                                                  }
                                                  if (isAbleChange) {
                                                    this.newReviewRatingConfigs.rating = event.target.value;
                                                  }
                                                }}
                                    >
                                      <option value="">별점 선택</option>
                                      <option value="41_100">3~5점(긍정)</option>
                                      <option value="1_40">1~2점(부정)</option>
                                      <option value="81_100">5점</option>
                                      <option value="61_80">4점</option>
                                      <option value="41_60">3점</option>
                                      <option value="21_40">2점</option>
                                      <option value="1_20">1점</option>
                                    </FormSelect>
                                  </div>
                                </td>
                                <td>
                                  <div className="reply-content active">
                                    <FormGroup>
                                      <FormSelect name="type"
                                                  value={this.newReviewRatingConfigs.type}
                                                  onChange={event => {
                                                    this.newReviewRatingConfigs.type = event.target.value;
                                                  }}
                                      >
                                        <option value="ai">ai</option>
                                        <option value="fixed">고정</option>
                                        <option value="na">미작성</option>
                                      </FormSelect>
                                    </FormGroup>
                                  </div>
                                </td>
                                <td>
                                  <div className="reply-content active">
                                    <FormGroup>
                                      <FormTextarea name="tone" value={this.newReviewRatingConfigs.tone}
                                                    onChange={event => {
                                                      this.newReviewRatingConfigs.tone = event.target.value;
                                                    }}
                                                    placeholder={this.newReviewRatingConfigs.type === 'ai' ? '해당 별점을 학습 할 답글예시를 입력해 주세요.' : '해당 별점에 고정 답글 내용을 입력해 주세요.'}/>
                                    </FormGroup>
                                  </div>
                                </td>
                                <td>
                                  <Row>
                                    <Col md="12">
                                      <FormCheckbox toggle small
                                                    checked={this.newReviewRatingConfigs.isShowWriterName}
                                                    onChange={event => {
                                                      this.newReviewRatingConfigs.isShowWriterName = !this.newReviewRatingConfigs.isShowWriterName;
                                                    }}>
                                        리뷰 작성자 {this.newReviewRatingConfigs.isShowWriterName ? '포함' : '미포함'}
                                      </FormCheckbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormCheckbox toggle small
                                                    checked={this.newReviewRatingConfigs.isShowProductName}
                                                    onChange={event => {
                                                      this.newReviewRatingConfigs.isShowProductName = !this.newReviewRatingConfigs.isShowProductName;
                                                    }}>
                                        상품명 {this.newReviewRatingConfigs.isShowProductName ? '포함' : '미포함'}
                                      </FormCheckbox>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Button theme="primary"
                                          onClick={event => this.addRatingConfigs(event)}><FeatherIcon icon="plus"
                                                                                                       size="15"
                                                                                                       className="nd-icon"/></Button>
                                </td>
                              </tr>

                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>


                    {/*<div className="section-tit">*/}
                    {/*  <h3>답글 자동 작성 주기</h3>*/}
                    {/*</div>*/}
                    {/*<Row>*/}
                    {/*  <Col md="6">*/}
                    {/*    <Row>*/}
                    {/*      <Col md="11">*/}
                    {/*      </Col>*/}
                    {/*    </Row>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}


                    <div className="btn-save">
                      <Button className="save-button"
                              onClick={event => this.saveAutoReplyConfig(event)}>저장</Button>
                    </div>
                  </div>
                </div>

              </div>
              :
              null
          }

      {/*  @observable modals = {*/}
      {/*  cafeSettingData: {},*/}
      {/*  isCafeSettingModal: false*/}
      {/*};*/}
        <Modal size="lg" className='modal-review-write-form' open={this.modals.isCafeSettingModal} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>카페 24 디자인 스킨 및 게시판 설정</ModalHeader>
          <ModalBody className="history-window-popup">
            {/*<p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>*/}
            <div className="history-popup-contents">
              <div className="popup-contents-main">
                <h4>상품정보</h4>
                <table className="s-table">
                  <colgroup>
                    <col width="170px"/>
                    <col width=""/>
                  </colgroup>
                  <tbody>
                  {/*<tr>*/}
                  {/*  <th>디자인 스킨 적용</th>*/}
                  {/*  <td>*/}
                  {/*    <Row>*/}
                  {/*      <Col md="12">*/}
                  {/*        <ul className="skin-list">*/}
                  {/*          {this.skins && this.skins.length > 0 ?*/}
                  {/*              this.skins.map((item, j) => (*/}
                  {/*                  <li key={j}>*/}
                  {/*                    <FormCheckbox toggle small checked={item.isSetScript}*/}
                  {/*                                  onChange={event => this.onChangeCheckedforApplySkin(event, item, ['isSetScript'])}>*/}
                  {/*                      [{item.usage_type === 'S' || item.usage_type === 'C' || item.usage_type === 'I' ? "PC" : ""}{item.usage_type === 'M' || item.usage_type === 'N' ? "모바일" : ""}]{item.skin_name}/{skinLanguageCodeKR(item.language_code)}<strong*/}
                  {/*                        className="warning-font">{item.published_in == '1' ? (' - ' + (item.usage_type == 'M' ? '모바일' : 'PC') + ' 사용스킨') : ''}</strong>*/}
                  {/*                    </FormCheckbox>*/}
                  {/*                  </li>*/}
                  {/*              ))*/}
                  {/*              : null*/}
                  {/*          }*/}
                  {/*        </ul>*/}
                  {/*      </Col>*/}
                  {/*    </Row>*/}
                  {/*    <Row>*/}
                  {/*      <Col className="text-right">*/}
                  {/*        <Button className="save-button" onClick={event => this.getApplySkins(true)}>스킨 리스트*/}
                  {/*          업데이트</Button>*/}
                  {/*      </Col>*/}
                  {/*    </Row>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr>*/}
                  {/*  <th>리뷰 게시판 ID</th>*/}
                  {/*  <td>*/}
                  {/*    <InputGroup><FormInput value={this.store.cafe24UseReviewBoardNo} id="cafe24UseReviewBoardNo"*/}
                  {/*                           name="cafe24UseReviewBoardNo" className="text-center"*/}
                  {/*                           onChange={event => this.onChangeInputs(event, this.store)}*/}
                  {/*                           placeholder="초기 셋팅 id는 4입니다."/></InputGroup>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  <tr>
                    <th>답글 설정</th>
                    <td className="text-left">
                      <p className="mb-2">쇼핑몰 관리자 > 게시판 > 게시판 관리 > 해당 게시판 설정된 댓글 또는 답변을 선택해 주세요.</p>
                      <p className="mb-2">아래의 설정 수정 하시면 기존 답변에 대한 확인 및 점검이 필요합니다.</p>
                      <FormRadio
                          name="comment"
                          checked={!this.setting.replyTyep || this.setting.replyTyep === "comment"}
                          onChange={() => {
                            let cf = window.confirm("댓글기능으로 변경하시면 기존 답변기능으로 작성된 데이터가 댓글기능과 동일하게 적용 될 수 있게 운영자가 점검이 필요합니다. 변경하시겠습니까?");
                            if(cf) {
                              this.setting.replyTyep = "comment";
                              this.saveAutoReplyConfig();
                            }
                          }}
                      >
                        댓글기능(추천)
                      </FormRadio>
                      <FormRadio
                          name="reply"
                          checked={this.setting.replyTyep === "reply"}
                          onChange={() => {
                            let cf = window.confirm("답변기능으로 변경하시면 기존 댓글기능으로 작성된 데이터가 댓글기능과 동일하게 적용 될 수 있게 운영자가 점검이 필요합니다. 변경하시겠습니까?");
                            if(cf) {
                              this.setting.replyTyep = "reply";
                              this.saveAutoReplyConfig();
                            }

                          }}
                      >
                        답변기능
                      </FormRadio>
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <th>카페24 리뷰 가져오기</th>*/}
                  {/*  {this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (*/}
                  {/*      <td className="text-left"><Button id="getPlatformReview" size="sm"*/}
                  {/*                                        onClick={event => this.setReviewSync('import')}>카페24 리뷰*/}
                  {/*        가져오기</Button>(* 온리플에서 작성된 모든 리뷰데이터가 삭제되니 주의부탁 드립니다.)</td>*/}
                  {/*  ) : <td className="text-left"><span className="text-primary">카페24 리뷰는 처음 시작할때 또는 기존 리뷰데이터를 초기화 후 사용이 가능합니다.</span><Button*/}
                  {/*      className="ml-2" size="sm" onClick={event => this.initReviewData()}>리뷰데이터 초기화</Button></td>}*/}
                  {/*</tr>*/}
                  {/*{this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (*/}
                  {/*    <tr>*/}
                  {/*      <th>카페24 리뷰 내보내기 해당 기능은 슈퍼관리자로 이동</th>*/}
                  {/*      <td className="text-left"><Button size="sm" onClick={event => this.setReviewSync('export')}>카페24*/}
                  {/*        리뷰 내보내기</Button>(* 온리플에서 작성된 모든 리뷰가 카페24 리뷰 게시판으로 데이터를 전달됩니다.)*/}
                  {/*      </td>*/}
                  {/*    </tr>*/}
                  {/*) : null}*/}
                  {/*{this.teamUser.emailId == "admin@onnomads.com" ? (*/}
                  {/*    <tr>*/}
                  {/*      <th>리뷰데이터 리셋</th>*/}

                  {/*    </tr>*/}
                  {/*) : null}*/}
                  </tbody>
                </table>
              </div>


              <div className="text-center">
                {/*<Button onClick={event => this.updateReview(this.modals.widgetData)}>리뷰작성</Button>*/}
                <Button className="ml-2" theme="light"
                        onClick={event => this.modals.isCafeSettingModal = false}>닫기</Button>
              </div>
            </div>

          </ModalBody>
        </Modal>

      </div>


      </>
    )
  }
}

export default ReplyWriteConfig;
