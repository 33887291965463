import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";



export class Review {

  @action updateReview = (data, cb) => {

    data.contents = util.escapeGraphQLString(data.contents);

    let params = {
      type: 'mutation',
      operation: 'updateReview',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateReviewSome = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewSome',
      variables: data,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateReviewDisplayStatus = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewDisplayStatus',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };



  @action updatePayMileage = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updatePayMileage',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateMileage = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateMileage',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action updateUserOrderByCafe24 = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateUserOrderByCafe24',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };




  @action updateReviewComment = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateReviewComment',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action genAIComment = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'genAIComment',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors && res.data.errors.length > 0) {
            res.data.data[params.operation] = {error : res.data.errors[0]};
          }
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action commentSmartCorrent = (data, cb) => {
    data.comment = util.escapeGraphQLString(data.comment);

    let params = {
      type: 'mutation',
      operation: 'commentSmartCorrent',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors && res.data.errors.length > 0) {
            res.data.data[params.operation] = {error : res.data.errors[0]};
          }
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action genAIReviewAnalysis = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'genAIReviewAnalysis',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.data.errors && res.data.errors.length > 0) {
            res.data.data[params.operation] = {error : res.data.errors[0]};
          }
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

@action selectAnalysisProductReview = (data, cb) => {

    let params = {
        type: 'mutation',
        operation: 'selectAnalysisProductReview',
        variables: data,
        getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
            if(typeof cb === 'function') {
                cb(res.data.data[params.operation]);
            }
            else {
                return res.data.data[params.operation];
            }

        })
        .catch(function (error) {
            if(typeof cb === 'function') {
                cb(error)
            }
            return error;
        });
};


    @action removeReviewMany = (data, cb) => {

        let params = {
            type: 'mutation',
            operation: 'removeReviewMany',
            variables: data,
            getData: 'data'
        };
        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation];
                }

            })
            .catch(function (error) {
                if(typeof cb === 'function') {
                    cb(error)
                }
                return error;
            });
    };

  @action removeAnalysisProductReview = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'removeAnalysisProductReview',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action getAnalysisList = (data, cb) => {

    let property = ['store_id', 'product_id', 'limit', 'page', 'sort', 'searchParams'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getAnalysisList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            if(typeof cb === 'function') {
              cb(res.data.data[params.operation]);
            }
            else {
              return res.data.data[params.operation];
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };


    @action getAnalysisWidgetList = (data, cb) => {

        let property = ['store_id', 'product_id', 'limit', 'page', 'sort', 'searchParams'];
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'query',
            operation: 'getAnalysisWidgetList',
            variables: variables,
            getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(res.status === 200) {
                    if(typeof cb === 'function') {
                        cb(res.data.data[params.operation]);
                    }
                    else {
                        return res.data.data[params.operation];
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };



  @action setReviewSync = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'setReviewSync',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action cloudFlareMng = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'cloudFlareMng',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };


  @action getReviewList = (data, cb) => {

    let property = ['store_id', 'limit', 'page', 'sort', 'displayStatus', 'widget_id', 'searchParams', 'isPopulateProductReply', 'shopNo'];

    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta, refProducts'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getReviewHistoryList = (data, cb) => {

    let property = ['review_id', 'type']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewHistoryList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getReviewMileageInfo = (data, cb) => {

    let property = ['review_id', 'userOrder_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getReviewMileageInfo',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation].data;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


    @action getStatusReviewBoard = (data, cb) => {

        let property = ['store_id', 'platform']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'query',
            operation: 'getStatusReviewBoard',
            variables: variables,
            getData: 'data'
        };
        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(typeof cb === 'function') {
                    cb(res.data.data[params.operation]);
                }
                else {
                    return res.data.data[params.operation].data;
                }

            })
            .catch(function (error) {
                if(typeof cb === 'function') {
                    cb(error)
                }
                return error;
            });
    };

  @action adminDelReview = (data, cb) => {

    let variables = data;

    let params = {
      type: 'mutation',
      operation: 'adminDelReview',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation])
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };




}
