import React,  {Component, useState, useEffect} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "underscore";
import moment from "moment";
import ReactDom from 'react-dom';
import { TermsAndPrivacy } from '../../../utils/termsAndPrivacy';


import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import {reactLocalStorage as Storage} from "reactjs-localstorage";

import './inAppOrder.scss';

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//inport images
import noImg from "../../../images/img/no_img.jpg";
import history from "../../../utils/history";
import {Redirect} from "react-router-dom";

const PopupDom = ({ children }) => {
  const el = document.getElementById('popupDom');
  return ReactDom.createPortal(children, el);
};


let planPd = {
  // r100: {val:'r100', name:"R-100", label: "쇼핑몰 창업 입문", price: 9900, eventPrice: 6600, eventName: "성공도약 기원 이벤트 초특가 6,600원", orderCountLte: 50, conditions:['event_5p1_first', 'event_10p2_first', 'regular']},
  r200: {val:'r200', name:"R-200", label: "월 평균 주문 수 200건 이하", price: 12900, orderCountLte: 200},
  r1000: {val:'r1000', name:"R-1000", label: "월 평균 주문 수 1000건 이하", price: 22900, orderCountLte: 1000},
  r2000: {val:'r2000', name:"R-2000", label: "월 평균 주문 수 2000건 이하", price: 52900, orderCountLte: 2000},
  r5000: {val:'r5000', name:"R-5000", label: "월 평균 주문 수 5000건 이하", price: 129000, orderCountLte: 5000}
}

let payment = {
  plan: planPd,
  payType: {
    regular: {val:'regular', label: "월 정기 결제", freePeriod: 0, period: 1, type:'autoPayment' },
  },
  planFirst: planPd,
  payTypeFirst: {
    event_5p1_first: {val:'event_5p1_first', label: "[첫 결제 이벤트] 5+1개월", freePeriod: 1, period: 6, type:'event', eventClass:'first', eventName: "첫 결제 WOW! 특별 혜택" },
    event_10p2_first: {val:'event_10p2_first', label: "[첫 결제 이벤트] 10+2개월", freePeriod: 2, period: 10, type:'event', eventClass:'first', eventName: "첫 결제 WOW! 특별 혜택" },
    regular: {val:'regular', label: "월 정기 결제", freePeriod: 0, period: 1, type:'autoPayment' },
  }
}


@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me,  updateInAppOrder:teamUser.updateInAppOrder, checkOrder:teamUser.checkOrder, freeEventApply: teamUser.freeEventApply }))
@inject(({ company }) => ({ getSalesReport: company.getSalesReport }))
@inject(({ common }) => ({ getDoc: common.getDoc }))

@observer
class InAppOrder extends Component {

  //http://localhost:3000/cafeInAppOrderReturnUrl?order_id=cafe24-20240215-446642580&result=success

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable salesReport = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);
  @observable isEventFree = false;
  //page
  @observable isStartup = false;
  @observable redirectInfo = {
    is: false,
    pathname: '/orderEnd'
  }
  @observable orderInfo = { };

  @observable InstallationCost = 55000;
  @observable payment = {
    plan: {},
    payType: {},
  };

  @observable serviceWillDate = {};
  @observable paymentData = {};

  constructor(props) {
    super(props);
    this.init()
  }


  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps)
    // console.log(prevState)
    // console.log(this.props.me)
  }

  @action
  init = async (key) => {

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      let getSalesReport = await this.props.getSalesReport({store_id: this.store._id});

      if(this.store.useExpiryDate) {
        this.store.isNotExpiryWithinOneMonth = !moment(this.store.useExpiryDate).isBefore(moment().add(1, 'months'));
      }

      this.isUse = false;
      if(this.store.isFreeStore || this.store.isPaidUser) {
        this.isUse = true;
      }

      this.salesReport = getSalesReport.data;
      let paymentData = await this.props.getDoc('getPaymentData', { store_id:this.teamUser.selStore_id });
      this.paymentData = paymentData.data;

      if(this.store.useExpiryDate) {
        this.store.isUseFirstOrder = true;
        this.paymentData.startDate = moment(this.store.useExpiryDate).add(1, 'days').format('YYYY.MM.DD');
      }
      else {
        this.paymentData.startDate = moment().format('YYYY.MM.DD');
      }

      //this.store.isUseFirstOrder = false;
      //this.salesReport.getMonthlySales.orderCount = 100;
      if(!this.store.isUseFirstOrder && this.salesReport.getMonthlySales.orderCount < 20) {
        this.paymentData.ablePlan = this.paymentData.planPd["r20"];
        this.isStartup = true;
      }
      else if(this.salesReport.getMonthlySales.orderCount < 1000) {
        this.paymentData.ablePlan = this.paymentData.planPd["r1000"];
      }
      else if(this.salesReport.getMonthlySales.orderCount < 2000) {
        this.paymentData.ablePlan = this.paymentData.planPd["r2000"];
      }
      else if(this.salesReport.getMonthlySales.orderCount < 3000) {
        this.paymentData.ablePlan = this.paymentData.planPd["r3000"];
      }

      // test용 this.salesReport.getMonthlySales.orderCount = 1000;
      this.store.useExpiryDateDiffNow = this.store.useExpiryDate ? moment(moment(this.store.useExpiryDate).format('YYYY.MM.DD')).diff(moment(), 'days') : 0;
      this.permissions = this.props.me.permissions;
      if(!this.store.isFreeStore) {
        this.checkOrder();
      }
      //this.initOrderOptions();
      // if(this.store.useExpiryDateDiffNow < 0) {
      //   this.checkOrder();
      // }
      this.initOrderInfo();
    }
    else {

      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };

  // initOrderOptions = async () => {
  //   if(!this.store.isUseFirstOrder) {
  //     this.payment = {
  //       plan: this.paymentData.planPd ? this.paymentData.planPd : payment.plan,
  //       payType: this.paymentData.payTypeFirst ? this.paymentData.payTypeFirst : payment.payTypeFirst,
  //     };
  //   }
  //   else {
  //     this.payment = {
  //       plan: this.paymentData.planPd ? this.paymentData.planPd : payment.plan,
  //       payType: this.paymentData.payType ? this.paymentData.payType : payment.payType,
  //     };
  //   }
  // };


  initOrderInfo = async () => {
    this.orderInfo = _.pick(this.store, 'name', 'tel', 'email');
    this.orderInfo.store_id = this.store._id;
    this.orderInfo.teamUser_id = this.teamUser.id;
    this.orderInfo.storeName = this.store.name;
    this.orderInfo.userName = this.teamUser.name ? this.teamUser.name : this.store.presidentName;
    this.orderInfo.payment = this.paymentData.payTypeFirst['regular'];
    this.orderInfo.plan = this.paymentData.ablePlan;
  };


  checkOrder = async () => {
    let updatedStore = await this.props.checkOrder({store_id: this.store._id});
    if(updatedStore.data && updatedStore.data.useExpiryDate) {
      this.store.useExpiryDate = updatedStore.data.useExpiryDate;
      this.store.useExpiryDateDiffNow = moment(moment(this.store.useExpiryDate).format('YYYY.MM.DD')).diff(moment(), 'days')+1;
    }
    if(this.store.useExpiryDateDiffNow < -5) {
      if(this.store.useExpiryDateDiffNow > 0) {
        alert('서비스 사용 기간이 '+this.store.useExpiryDateDiffNow+'일 남았습니다.') ;
      }
      else if(this.store.useExpiryDateDiffNow > -7) {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 서비스 연장을 진행해 주세요.') ;
      }
      else if(this.store.useExpiryDateDiffNow > -14) {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 일부 기능이 제한됩니다. 정상적인 서비스 사용을 위해 연장을 진행해 주세요.') ;
      }
      else  {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 서비스 이용을 위해 연장을 진행해 주세요.') ;
      }
    }
  };

  // 팝업창 닫기
  closePostCode = async (event) => {
    this.isPopupOpen = false;
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.orderInfo[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  onSelectedPayType = async (value) => {
    this.orderInfo.plan = this.paymentData.ablePlan;
    if(value.val === "free") {
      this.isEventFree = true;
    }
    else {
      this.isEventFree = false;
    }
    this.orderInfo.payment = value;

  };



  onFreeSubmit = async (event) => {
    let _this = this;
    if(!this.orderInfo.privateAgree) {
      alert('서비스 이용약관을 동의해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('개인정보 이용약관을 동의해 주세요.');
      return false;
    }
    else {
      this.orderInfo.orderName = this.orderInfo.plan.name;
      this.orderInfo.payType = "free";
      this.orderInfo.plan = this.orderInfo.plan.val;

      let cf = window.confirm('무료를 이용하시면 첫결제 이벤트는 사라집니다. 무료 이용을 진행하시겠습니까?');
      if(cf) {
        this.props.freeEventApply(this.orderInfo, function(res) {

          if(res.data && res.data.endDate) {
            toast('서비스 신청이 완료되었습니다');
            window.location.href = _this.redirectInfo.pathname;
            //_this.redirectInfo.is = true;
          }
          else {
            toast('서비스 신청이 정상적으로 처리되지 않았습니다.');
          }
        });
      }
      else {
        return false;
      }
    }
  };

  onSubmit = async (event) => {
    let _this = this;
    event.preventDefault();
    if(!this.orderInfo.payment) {
      alert('상품을 선택해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('서비스 이용약관을 동의해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('개인정보 이용약관을 동의해 주세요.');
      return false;
    }
    else {

      if(this.isStartup && this.orderInfo.payment.period > 1) {
        this.orderInfo.orderName = this.orderInfo.plan.name;
        this.orderInfo.orderPlanPrice = this.orderInfo.plan.price;
        this.orderInfo.orderPlanOrgPrice = this.orderInfo.plan.orgPrice;
        this.orderInfo.period = 6;
        this.orderInfo.freePeriod = 0;
        this.orderInfo.type = this.orderInfo.payment.type;

        this.orderInfo.orderOrgAmount = this.orderInfo.orderPlanOrgPrice * this.orderInfo.period;
        this.orderInfo.orderOrgAmount += this.InstallationCost;
        this.orderInfo.orderOrgAmountKrw = this.orderInfo.orderOrgAmount.toLocaleString('ko-KR');

        this.orderInfo.orderAmount = this.orderInfo.orderPlanPrice * (this.orderInfo.period - this.orderInfo.freePeriod);
        this.orderInfo.orderAmount += this.InstallationCost;
        this.orderInfo.orderAmountKrw = this.orderInfo.orderAmount.toLocaleString('ko-KR');

        this.orderInfo.orderSaleAmount = this.orderInfo.orderOrgAmount - this.orderInfo.orderAmount;
        this.orderInfo.orderSaleAmount += this.InstallationCost;
        this.orderInfo.orderSaleAmountKrw = this.orderInfo.orderSaleAmount.toLocaleString('ko-KR');

      }
      else {
        this.orderInfo.orderName = this.orderInfo.plan.name;
        this.orderInfo.orderPlanPrice = this.orderInfo.plan.price;
        this.orderInfo.orderPlanOrgPrice = this.orderInfo.plan.orgPrice;
        this.orderInfo.period = this.orderInfo.payment.period;
        this.orderInfo.freePeriod = this.orderInfo.payment.freePeriod;
        this.orderInfo.type = this.orderInfo.payment.type;

        this.orderInfo.orderOrgAmount = this.orderInfo.orderPlanOrgPrice * this.orderInfo.period;
        this.orderInfo.orderOrgAmountKrw = this.orderInfo.orderOrgAmount.toLocaleString('ko-KR');

        this.orderInfo.orderAmount = this.orderInfo.orderPlanPrice * (this.orderInfo.period - this.orderInfo.freePeriod);
        this.orderInfo.orderAmountKrw = this.orderInfo.orderAmount.toLocaleString('ko-KR');

        this.orderInfo.orderSaleAmount = this.orderInfo.orderOrgAmount - this.orderInfo.orderAmount;
        this.orderInfo.orderSaleAmountKrw = this.orderInfo.orderSaleAmount.toLocaleString('ko-KR');

      }




      if(!this.store.isUseFirstOrder) {
        this.orderInfo.endDateMoment = moment(this.orderInfo.startDate).add(this.orderInfo.period, 'months');
      }
      else {
        this.orderInfo.endDateMoment = moment(this.orderInfo.startDate).add(this.orderInfo.period, 'months');
      }
      this.orderInfo.startDate = this.paymentData.startDate;
      this.orderInfo.endDate = this.orderInfo.endDateMoment.format('YYYY.MM.DD');

      if(this.orderInfo.type === 'autoPayment') {
        this.orderInfo.automaticPayment = 'T';
        this.orderInfo.endDate = '2999-12-31';
        this.orderInfo.nextPayDate = moment(this.orderInfo.startDate).add(1, 'months').format('YYYY.MM.DD');
      }
      else {
        this.orderInfo.automaticPayment = 'F';
      }

    }

    this.orderInfo.payType = this.orderInfo.payment.val;
    this.orderInfo.plan = this.orderInfo.plan.val;

    this.props.updateInAppOrder(this.orderInfo, function(res) {

      if(res.errors && res.errors.length > 0) {
        if(res.errors[0].message == 'fail password') {

        }
        else {
          alert(res.errors[0].message);
        }
      }
      else {
        let name = 'review-widget';
        let specs = 'menubar=no,status=no,toolbar=no';
        let url = res.data.confirmation_url;
        let orderWindow = window.open(url, name, specs);

        let timeAfter = 0;
        let iVOrderCheck = setInterval(function() {
          timeAfter = timeAfter+2;
          let isInAppOrderCp = Storage.get('isInAppOrderCp');
          if(isInAppOrderCp == 'end' || timeAfter > 600) {
            clearInterval(iVOrderCheck);
            Storage.remove('isInAppOrderCp');
            _this.props.history.push('/inAppOrderList');
          }
        }, 2000);


        //cafeInAppOrderReturnUrl?order_id=cafe24-20230913-446425016&result=fail
      }

    });

  };


  render() {
    let _this = this;

    function UseDdayCount(props) {
      let createdAt = _this.store.createdAt;
      const [timeLeft, setTimeLeft] = useState("");

      useEffect(() => {
        const dDay = moment(createdAt).add(3, "days");

        const updateCountdown = () => {
          const now = moment();
          const duration = moment.duration(dDay.diff(now));

          if (duration.asSeconds() > 0) {
            const hours = String(duration.hours()).padStart(2, "0");
            const minutes = String(duration.minutes()).padStart(2, "0");
            const seconds = String(duration.seconds()).padStart(2, "0");

            setTimeLeft(`${hours}:${minutes}:${seconds}`);
          } else {
            setTimeLeft("D-Day Expired!");
          }
        };

        const interval = setInterval(updateCountdown, 1000);

        // 초기 호출
        updateCountdown();

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => clearInterval(interval);
      }, [createdAt]);

      return <span className="dday-count">{timeLeft}</span>;
    };

    return (
        <>
        {this.redirectInfo.is ? (
              <Redirect to={{pathname: this.redirectInfo.pathname}} />
          ) : (
            <div className="main-content ">
              <PageTitle/>

              {(this.isLogin && this.teamUser.emailId) ? (
                      <div className="order-box">
                        {!this.store.isNotExpiryWithinOneMonth && this.salesReport.getMonthlySales && this.paymentData && this.paymentData.ablePlan ? (
                            <form onSubmit={e => this.onSubmit(e)}>

                              <div className="section-box basic-info-table">
                                <div className="section-tit">
                                  <h3>플랜 선택하기</h3>
                                  <div className="table-sub-tit">
                                    <span>플랜은 주문 수 기준으로 책정됩니다.</span>
                                  </div>
                                </div>
                                <div className="section-cont">
                                  <table>
                                    <colgroup>
                                      <col width=""/>
                                      <col width="80%"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                      <th>쇼핑몰 이름</th>
                                      <td>
                                        <span>{this.orderInfo.storeName}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>최근 한달 주문 수</th>
                                      <td>
                                        <strong>{this.salesReport.getMonthlySales.orderCount}</strong>&nbsp;건
                                        <span className="order-date">(<strong
                                            className="order-date-txt start">{moment(this.salesReport.getMonthlySales.start_date).format('YYYY.MM.DD')}</strong>~<strong
                                            className="order-date-txt end">{moment(this.salesReport.getMonthlySales.end_date).format('YYYY.MM.DD')}</strong>)</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>적용 플랜</th>
                                      <td>
                                        {this.paymentData.ablePlan ? (
                                            <span>{this.paymentData.ablePlan.label} 전용 플랜</span>
                                        ) : (
                                            <span>기타 플랜</span>
                                        )}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <div className="plan">
                                    <div className="plan-section">
                                      <div className="plan-item-tit">월구독 플랜</div>
                                      <div className="plan-section-wrap">
                                        <div className={"plan-item" + ((this.orderInfo.payment && this.orderInfo.payment.val) === "regular" ? " selected": "")}
                                             onClick={event => this.onSelectedPayType(this.paymentData.payTypeFirst['regular'])}>
                                          <div className="plan-selected">선택됨</div>
                                          <div className="plan-item-top">
                                            <div className="plan-tag"><span
                                                className="plan-tag-txt">{this.paymentData.ablePlan.name}</span></div>
                                            <div className="plan-cost"><span
                                                className="plan-cost-val">{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(this.paymentData.ablePlan.price)}</span>원
                                              / 월
                                            </div>
                                            {/*<div className="plan-cost-sub">(월 <span className="plan-cost-val">16,450</span>원에 이용)</div>*/}
                                          </div>
                                          <div className="plan-item-content">
                                            <ul>
                                              <li className="order-list">
                                                <div className="order-tit">
                                                  <span>결제 상품</span>
                                                </div>
                                                <div className="order-cont">
                                                  <span>{this.paymentData.ablePlan.name} - 월 정기 결제</span>
                                                </div>
                                              </li>
                                              <li className="order-list">
                                                <div className="order-tit">
                                                  <span>이용 기간</span>
                                                </div>
                                                <div className="order-cont">
                                                  <span>{this.paymentData.startDate}</span>&nbsp;~&nbsp;
                                                  <span>해지 시까지</span>
                                                </div>
                                              </li>
                                              <li className="order-list">
                                                <div className="order-tit">
                                                  <span>다음 결제일</span>
                                                </div>
                                                <div className="order-cont">
                                                  <span>{moment().add(1, 'months').format('YYYY.MM.DD')}</span>
                                                </div>
                                              </li>
                                              <li className="order-list">
                                                <div className="order-tit">
                                                  <span>결제 금액</span>
                                                </div>
                                                <div className="order-cont">
                                                  <span>{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(this.paymentData.ablePlan.price)}</span>원
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="plan-item-bottom">
                                            <button className="btn-plan-select" type="button">선택하기</button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="plan-desc">
                                        <ul>
                                          {this.isStartup ? (
                                              <>
                                                <li><strong>- 주문량이 월 20건을 초과할 경우,<br />다음 달에는 "파이팅!! 스타트업" 이벤트가 종료되며<br />정상 가격으로 구독 결제가 진행됩니다.</strong></li>
                                                <li><strong>- 설치및 설정은 고객님께서 직접 진행하셔야 합니다.</strong></li>
                                              </>
                                          ) : null}
                                          <li>- 중도 해지 시 결제한 이용 기간까지 사용이 가능합니다.</li>
                                          <li>- 상기 결제금액은 부가세 포함 금액입니다.</li>
                                        </ul>
                                      </div>
                                    </div>
                                    {!this.store.isUseFirstOrder ?
                                        <div className="plan-section">
                                          <div className="plan-item-tit">스타트업 패키지 EVENT! 타임 <UseDdayCount /></div>
                                          <div className="plan-section-wrap">

                                            {
                                              Object.keys(this.paymentData.payTypeFirst).map((key, i) => {
                                                if (key === "free" && !this.isStartup) {
                                                  return (
                                                      <div className={"plan-item" + ((this.orderInfo.payment && this.orderInfo.payment.val) === key ? " selected": "")} key={i}
                                                           onClick={event => this.onSelectedPayType(this.paymentData.payTypeFirst[key])}>
                                                        <div className="plan-selected">선택됨</div>
                                                        <div className="plan-item-top">
                                                          <div className="plan-tag"><span
                                                              className="plan-tag-txt">14일 무료 이벤트</span></div>
                                                          <div className="plan-cost"><span
                                                              className="plan-cost-val">무료</span>
                                                          </div>
                                                          {/*<div className="plan-cost-sub">(월 <span className="plan-cost-val">16,450</span>원에 이용)</div>*/}
                                                        </div>
                                                        <div className="plan-item-content">
                                                          <ul>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 상품</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>14일 무료 이벤트</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>이용 기간</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{moment().format('YYYY.MM.DD')}</span>&nbsp;~&nbsp;
                                                                <span>{moment().add(14, 'days').format('YYYY.MM.DD')}</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 금액</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>0</span>원
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        <div className="plan-item-bottom">
                                                          <button className="btn-plan-select" type="button">선택하기
                                                          </button>
                                                        </div>
                                                      </div>
                                                  )
                                                } else if (key.indexOf("event") > -1 && !this.isStartup) {
                                                  return (
                                                      <div className={"plan-item" + ((this.orderInfo.payment && this.orderInfo.payment.val) === key ? " selected": "")} key={i}
                                                           onClick={event => this.onSelectedPayType(this.paymentData.payTypeFirst[key])}>
                                                        <div className="plan-selected">선택됨</div>
                                                        <div className="plan-item-top">
                                                          <div className="plan-tag"><span
                                                              className="plan-tag-txt">{this.paymentData.payTypeFirst[key].label}</span>
                                                          </div>
                                                          {/*<div className="plan-cost"><span*/}
                                                          {/*    className="plan-cost-val">{new Intl.NumberFormat('ko-KR', { currency: 'KRW' }).format(this.paymentData.ablePlan.price*(this.paymentData.payTypeFirst[key].period-this.paymentData.payTypeFirst[key].freePeriod))}</span>원 / 년*/}
                                                          {/*</div>*/}
                                                          <div className="plan-cost"><span
                                                              className="plan-cost-val">{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(Math.ceil(this.paymentData.ablePlan.price * (this.paymentData.payTypeFirst[key].period - this.paymentData.payTypeFirst[key].freePeriod) / this.paymentData.payTypeFirst[key].period))}</span>원
                                                            / 월
                                                          </div>
                                                          <div className="plan-cost-sub">(년 <span
                                                              className="plan-cost-val">{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(this.paymentData.ablePlan.price * (this.paymentData.payTypeFirst[key].period - this.paymentData.payTypeFirst[key].freePeriod))}</span>원에
                                                            이용)
                                                          </div>
                                                        </div>
                                                        <div className="plan-item-content">
                                                          <ul>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 상품</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{this.paymentData.ablePlan.name} - {this.paymentData.payTypeFirst[key].eventName}</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>이용 기간</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{moment().format('YYYY.MM.DD')}</span>&nbsp;~&nbsp;
                                                                <span>{moment().add(this.paymentData.payTypeFirst[key].period, 'months').format('YYYY.MM.DD')}</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 금액</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(this.paymentData.ablePlan.price * (this.paymentData.payTypeFirst[key].period - this.paymentData.payTypeFirst[key].freePeriod))}</span>원
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        <div className="plan-item-bottom">
                                                          <button className="btn-plan-select" type="button">선택하기
                                                          </button>
                                                        </div>
                                                      </div>
                                                  )
                                                }
                                                else if (key.indexOf("event") > -1 && this.isStartup && (this.isStartup && this.paymentData.payTypeFirst[key].period < 7)) {
                                                  return (
                                                      <div className={"plan-item" + ((this.orderInfo.payment && this.orderInfo.payment.val) === key ? " selected": "")} key={i}
                                                           onClick={event => this.onSelectedPayType(this.paymentData.payTypeFirst[key])}>
                                                        <div className="plan-selected">선택됨</div>
                                                        <div className="plan-item-top">
                                                          <div className="plan-tag"><span
                                                              className="plan-tag-txt">스타트업 응원 이벤트</span>
                                                          </div>
                                                          {/*<div className="plan-cost"><span*/}
                                                          {/*    className="plan-cost-val">{new Intl.NumberFormat('ko-KR', { currency: 'KRW' }).format(this.paymentData.ablePlan.price*(this.paymentData.payTypeFirst[key].period-this.paymentData.payTypeFirst[key].freePeriod))}</span>원 / 년*/}
                                                          {/*</div>*/}
                                                          <div className="plan-cost"><span
                                                              className="plan-cost-val">6개월 안심 결제</span>
                                                          </div>
                                                          {/*<div className="plan-cost-sub">(년 <span*/}
                                                          {/*    className="plan-cost-val">{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format(this.paymentData.ablePlan.price * (this.paymentData.payTypeFirst[key].period - this.paymentData.payTypeFirst[key].freePeriod))}</span>원에*/}
                                                          {/*  이용)*/}
                                                          {/*</div>*/}
                                                        </div>
                                                        <div className="plan-item-content">
                                                          <ul>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 상품</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <strong>6개월 + 설치 및 설정대행 서비스</strong>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>이용 기간</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{moment().format('YYYY.MM.DD')}</span>&nbsp;~&nbsp;
                                                                <span>{moment().add(this.paymentData.payTypeFirst[key].period, 'months').format('YYYY.MM.DD')}</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>결제 금액</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>{new Intl.NumberFormat('ko-KR', {currency: 'KRW'}).format((this.paymentData.ablePlan.price * 6)+this.InstallationCost)}</span>원
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>부가 설명 1</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>6개월 동안 추가 결제 없이사용</span>
                                                              </div>
                                                            </li>
                                                            <li className="order-list">
                                                              <div className="order-tit">
                                                                <span>부가 설명 2</span>
                                                              </div>
                                                              <div className="order-cont">
                                                                <span>최적화된 설치 및<br />설정 대행서비스 지원</span>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        <div className="plan-item-bottom">
                                                          <button className="btn-plan-select" type="button">선택하기
                                                          </button>
                                                        </div>
                                                      </div>
                                                  )
                                                }

                                                // if(this.paymentData.payTypeFirst)

                                              })
                                            }

                                          </div>
                                          <div className="plan-desc">
                                            <ul>
                                              {this.salesReport.getMonthlySales.orderCount > 20 ? (
                                                  <li>- ‘14일 무료 이벤트’는 14일동안 무료로 모든 기능을 이용하실 수 있습니다.</li>
                                              ) : null}
                                              {this.isStartup ? (
                                                  <>
                                                    <li><strong>- 주문량이 급증하더라도 추가 비용 없이 6개월 동안<br />안정적인 가격으로 이용하실 수 있습니다.</strong></li>
                                                    <li><strong>- HTML, CSS를 잘 다루지 못해도<br />최적화된 설치 과정을 쉽고 빠르게 해결해 드립니다.</strong></li>
                                                    <li><strong>- 처음이라 복잡한 설정이 걱정되신다면, 초기 설치와 설정을 대신해 드립니다.</strong></li>
                                                  </>
                                              ) : null}
                                              <li>- ‘특별 기간 EVENT!’ 결제 금액은 환불이 불가합니다.</li>
                                              <li>- 특별 기간 EVENT!는 3가지 중 한번 참여할 수 있습니다. (중복참여 불가)</li>
                                              <li>- 상기 결제금액은 부가세 포함 금액입니다.</li>
                                              <li>- 해당 이벤트 기간 동안 주문수가 달라져도 추가 결제되지 않습니다.</li>
                                            </ul>
                                          </div>
                                        </div>
                                        : null}
                                  </div>
                                </div>
                              </div>

                              <div className="section-box basic-info-box">
                                <div className="section-tit">
                                  <h3>주문자 정보</h3>
                                </div>
                                <div className="section-cont">
                                  <table>
                                    <colgroup>
                                      <col width=""/>
                                      <col width="80%"/>
                                    </colgroup>
                                    <tbody>
                                    <tr className="info-id">
                                      <th>쇼핑몰 이름</th>
                                      <td>
                                        <input name="storeName" type="text" className="table-input-text"
                                               defaultValue={this.orderInfo.storeName} disabled={true}/>
                                      </td>
                                    </tr>
                                    <tr className="info-id">
                                      <th>관리자 이름</th>
                                      <td>
                                        <input name="userName" type="text" className="table-input-text"
                                               defaultValue={this.orderInfo.userName}
                                               onChange={e => this.onChangeValue(e)}/>
                                      </td>
                                    </tr>
                                    <tr className="info-phone">
                                      <th>담당자 전화번호</th>
                                      <td>
                                        <input name="tel" type="text" className="table-input-text"
                                               defaultValue={this.orderInfo.tel} onChange={e => this.onChangeValue(e)}/>
                                        <p className="guide-text">온리플 서비스 이용 관련 안내를 받아 보게 될 관리자 전화번호입니다.</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>관리자 이메일</th>
                                      <td>
                                        <input name="email" type="text" className="table-input-text"
                                               defaultValue={this.orderInfo.email}
                                               onChange={e => this.onChangeValue(e)}/>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="section-box basic-info-box">
                                <div className="section-tit">
                                  <h3>약관 동의</h3>
                                </div>
                                <div className="section-cont">
                                  <table>
                                    <colgroup>
                                      <col width=""/>
                                      <col width="80%"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                      <th>서비스 이용약관</th>
                                      <td>
                                        <div className={"terms-privacy"}
                                             dangerouslySetInnerHTML={{__html: TermsAndPrivacy.termsOfService}}></div>
                                        <FormGroup className="float-right serviceAgreeCheck">
                                          <FormCheckbox name="serviceAgree" toggle
                                                        checked={this.orderInfo.serviceAgree || false}
                                                        onChange={event => this.onChangeChecked(event, this.orderInfo, ['serviceAgree'])}>
                                            유료서비스 이용약관 동의
                                          </FormCheckbox>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>개인정보 이용약관</th>
                                      <td>
                                        <div className={"terms-privacy"}
                                             dangerouslySetInnerHTML={{__html: TermsAndPrivacy.privacyPolicy}}></div>

                                        <FormGroup className="float-right serviceAgreeCheck">
                                          <FormCheckbox name="privateAgree" toggle
                                                        checked={this.orderInfo.privateAgree || false}
                                                        onChange={event => this.onChangeChecked(event, this.orderInfo, ['privateAgree'])}>
                                            개인정보 이용약관 동의
                                          </FormCheckbox>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="btn-save">
                                {this.isEventFree ? (
                                    <input type="button" onClick={event => this.onFreeSubmit()}
                                           value={"무료로 사용하기"}
                                           className="save-button"/>
                                ) : (
                                    <>
                                      {!this.isEventFree && this.orderInfo && this.orderInfo.payment && this.orderInfo.payment.type ? (
                                          <input type="button" onClick={event => this.onSubmit(event)} value={"주문하기"} className="save-button"/>) :
                                          <input type="button" value={"주문하기"} disabled={true} className="save-button disabled"/>}
                                    </>
                                )}


                              </div>
                            </form>) : (
                            <div className="order-box">
                              {this.salesReport.getMonthlySales && this.paymentData && this.paymentData.ablePlan ? (
                                      <div className="section-box basic-info-table">
                                        <div className="section-tit">
                                          <h3>현재 플랜</h3>
                                          <table>
                                            <colgroup>
                                              <col width=""/>
                                              <col width="80%"/>
                                            </colgroup>
                                            <tbody>
                                            <tr>
                                              <th>쇼핑몰 이름</th>
                                              <td>
                                                <span>{this.orderInfo.storeName}</span>
                                              </td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*  <th>최근 한달 주문 수</th>*/}
                                            {/*  <td>*/}
                                            {/*    <strong>{this.salesReport.getMonthlySales.orderCount}</strong>&nbsp;건*/}
                                            {/*    <span className="order-date">(<strong*/}
                                            {/*        className="order-date-txt start">{moment(this.salesReport.getMonthlySales.start_date).format('YYYY.MM.DD')}</strong>~<strong*/}
                                            {/*        className="order-date-txt end">{moment(this.salesReport.getMonthlySales.end_date).format('YYYY.MM.DD')}</strong>)</span>*/}
                                            {/*  </td>*/}
                                            {/*</tr>*/}
                                            <tr>
                                              <th>적용 플랜</th>
                                              <td>
                                                {this.store.orderName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>이용 기간</th>
                                              <td>
                                                ~ {moment(this.store.useExpiryDate).format('YYYY.MM.DD')}
                                              </td>
                                            </tr>

                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                          ) : null}
                                        </div>
                                        )}
                                      </div>
                                  ) :
                                  <p className='empty'><a href='/signin'>로그인하세요.</a></p>
                              }
                              {/*{this.orderInfo.payment ? null : null}*/}
                            </div>


        )}
        </>
    )
  }
}

export default InAppOrder;
