import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import _ from "underscore";
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import SmallStats from "../../../components/common/SmallStats";
import ReviewsOverview from "../../../components/common/ReviewsOverview";
import ReviewsSentiment from "../../../components/common/ReviewsSentiment";
import NewDraft from "../../../components/common/NewDraft";
import Discussions from "../../../components/common/Discussions";
import TopReferrals from "../../../components/common/TopReferrals";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeatherIcon from 'feather-icons-react';
import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import moment from "moment";
import Calendar from "react-calendar";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";


@inject(({ common }) => ({ getDashBoardReport: common.getDashBoardReport }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

@observer
class Dashboard extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //page
  @observable firstDataDay;
  @observable dashBoardReport;
  @observable reDashBoardData = {
    orderCount: [],
    cancelCount: [],
    reviewCount: [],
    currentMonthReviewCount: [],
    currentMonthReviewDays: [],
    pastMonthReviewCount: [],
    pastMonthReviewDays: [],
  };
  @observable smallStats = [];


  //list
  @observable searchParams = {
    keyword: ''
  };
  @observable list = [];

  constructor(props) {
    super(props);
    this.init()
  }


  @action
  init = async (key) => {
    if(this.props.isLogin) {
      let _this = this;
      this.isLoading = true;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.permissions = this.props.me.permissions;

      this.searchParams = {
        store_id: this.store._id
      }

      this.getDashBaordData()
      this.isLoading = false;

    }
  };

  @action
  getDashBaordData = async () => {
    this.dashBoardReport = await this.props.getDashBoardReport(this.searchParams);
    this.firstDataDay = moment().subtract(7, 'days');
    this.lastWeekDay = moment().subtract(14, 'days');
    this.pastMonthFirstDay = moment().subtract(this.dashBoardReport.reviewDaysCount, 'days');
    this.dashBoardReport.days = [];

    this.refactoringData();
  }

  @action
  refactoringData = (data) => {

    for(let i in this.dashBoardReport.report) {
      this.dashBoardReport.report[i].day = moment(this.dashBoardReport.report[i].day).format('YYYY-MM-DD');
    }
    let reportCountsByDay = _.indexBy(this.dashBoardReport.report, 'day');
    let reviewCountsByDay = _.indexBy(this.dashBoardReport.review, '_id');
    // console.log(reviewCountsByDay, this.dashBoardReport.reviewDaysCount)


    this.reDashBoardData.orderCountSum = this.dashBoardReport.thisWeekSalesCount.orderCount;
    this.reDashBoardData.pastWeekOrderCountSum = this.dashBoardReport.lastWeekSalesCount.orderCount;
    this.reDashBoardData.cancelCountSum = 0;
    this.reDashBoardData.reviewCountSum = 0;
    this.reDashBoardData.pastWeekCountSum = 0;
    let cDayIndex = 0;
    for(let i = 0; i < 14; i++) {
      let currentDate = moment(this.lastWeekDay).add(i, 'days').format('YYYY-MM-DD');

      if(i > 7) {
        cDayIndex = i - 8;
        // if(reportCountsByDay[currentDate]) {
        //   this.reDashBoardData.orderCount[cDayIndex] = reportCountsByDay[currentDate].orderCount;
        //   this.reDashBoardData.orderCountSum += reportCountsByDay[currentDate].orderCount;
        //   this.reDashBoardData.cancelCount[cDayIndex] = reportCountsByDay[currentDate].cancelCount
        //   this.reDashBoardData.cancelCountSum += reportCountsByDay[currentDate].cancelCount;
        // }
        // else {
        //   this.reDashBoardData.orderCount[cDayIndex] = 0;
        //   this.reDashBoardData.cancelCount[cDayIndex] = 0;
        // }

        if(reviewCountsByDay[currentDate]) {
          this.reDashBoardData.reviewCount[cDayIndex] = reviewCountsByDay[currentDate].count
          this.reDashBoardData.reviewCountSum += reviewCountsByDay[currentDate].count;
        }
        else {
          this.reDashBoardData.reviewCount[cDayIndex] = 0;
        }
        this.dashBoardReport.days.push(currentDate)
      }
      else {
        // if(reviewCountsByDay[currentDate]) {
        //   this.reDashBoardData.pastWeekCountSum += reviewCountsByDay[currentDate].count;
        // }
        // if(reportCountsByDay[currentDate]) {
        //   this.reDashBoardData.pastWeekOrderCountSum += reportCountsByDay[currentDate].orderCount;
        // }
      }
      //this.dashBoardReport.days.push(currentDate)
    }


    let currentMonth = moment().format('YYYY-MM');
    let cIndex = 0;
    let pIndex = 0;
    for(let i = 0; i < this.dashBoardReport.reviewDaysCount+1; i++) {
      let currentDate = moment(this.pastMonthFirstDay).add(i, 'days');
      let formattedDate = currentDate.format('YYYY-MM-DD');
      let monthOfCurrentDate = currentDate.format('YYYY-MM');

      if(monthOfCurrentDate === currentMonth) {
        // 이번달 리뷰수
        if(reviewCountsByDay[formattedDate]) {
          this.reDashBoardData.currentMonthReviewCount[cIndex] = reviewCountsByDay[formattedDate].count;
          this.reDashBoardData.currentMonthReviewCountSum += reviewCountsByDay[formattedDate].count;
        }
        else {
          this.reDashBoardData.currentMonthReviewCount[cIndex] = 0;
        }
        this.reDashBoardData.currentMonthReviewDays.push(formattedDate);
        cIndex++;
      } else {
        // 지난달 리뷰수
        if(reviewCountsByDay[formattedDate]) {
          this.reDashBoardData.pastMonthReviewCount[pIndex] = reviewCountsByDay[formattedDate].count;
          this.reDashBoardData.pastMonthReviewCountSum += reviewCountsByDay[formattedDate].count;
        }
        else {
          this.reDashBoardData.pastMonthReviewCount[pIndex] = 0;
        }
        this.reDashBoardData.pastMonthReviewDays.push(formattedDate);
        pIndex++;
      }
    }

    // console.log(this.reDashBoardData.currentMonthReviewCount)
    // console.log(this.reDashBoardData.currentMonthReviewDays)
    // console.log(this.reDashBoardData.pastMonthReviewCount)

    this.applyGraphData();
  }

  @action
  applyGraphData = () => {

    let incPer = "-";
    let increase = this.reDashBoardData.reviewCountSum - this.reDashBoardData.pastWeekCountSum >= 0;
    let decrease = this.reDashBoardData.reviewCountSum - this.reDashBoardData.pastWeekCountSum < 0;
    if(this.reDashBoardData.reviewCountSum > 0 && this.reDashBoardData.pastWeekCountSum > 0) {
      if(increase) {
        incPer = this.reDashBoardData.reviewCountSum - this.reDashBoardData.pastWeekCountSum === 0 ? 0 : (this.reDashBoardData.reviewCountSum - this.reDashBoardData.pastWeekCountSum) / this.reDashBoardData.pastWeekCountSum * 100;
      } else {
        incPer = this.reDashBoardData.pastWeekCountSum - this.reDashBoardData.reviewCountSum === 0 ? 0 : (this.reDashBoardData.pastWeekCountSum - this.reDashBoardData.reviewCountSum) / this.reDashBoardData.pastWeekCountSum * 100;
      }
      incPer = parseInt(incPer);
    }
    console.log(this.reDashBoardData.reviewCountSum, this.reDashBoardData.pastWeekCountSum)

    let oincPer = "-";
    let oincrease = this.reDashBoardData.orderCountSum - this.reDashBoardData.pastWeekOrderCountSum >= 0;
    let odecrease = this.reDashBoardData.orderCountSum - this.reDashBoardData.pastWeekOrderCountSum < 0;
    if(this.reDashBoardData.orderCountSum > 0 && this.reDashBoardData.pastWeekOrderCountSum > 0) {
      if(oincrease) {
        oincPer = this.reDashBoardData.orderCountSum - this.reDashBoardData.pastWeekOrderCountSum === 0 ? 0 : (this.reDashBoardData.orderCountSum - this.reDashBoardData.pastWeekOrderCountSum) / this.reDashBoardData.pastWeekOrderCountSum * 100;
      } else {
        oincPer = this.reDashBoardData.pastWeekOrderCountSum - this.reDashBoardData.orderCountSum === 0 ? 0 : (this.reDashBoardData.pastWeekOrderCountSum - this.reDashBoardData.orderCountSum) / this.reDashBoardData.pastWeekOrderCountSum * 100;
      }
      oincPer = parseInt(oincPer);
    }

    this.smallStats = [
      {
        label: "최근 7일 리뷰수",
        value: this.reDashBoardData.reviewCountSum,
        percentage: incPer + "%",
        increase: increase,
        decrease: decrease,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: this.reDashBoardData.reviewCount
          }
        ]
      },
      {
        label: "최근 7일 판매건수",
        value: this.reDashBoardData.orderCountSum,
        percentage: oincPer + "%",
        increase: oincrease,
        decrease: odecrease,
        chartLabels: [this.reDashBoardData.pastWeekOrderCountSum, this.reDashBoardData.orderCountSum],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Week",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [this.reDashBoardData.pastWeekOrderCountSum]
          }
        ]
      }
    ];


    // this.smallStats = [
    //   {
    //     label: "최근 7일 리뷰수",
    //     value: "239",
    //     percentage: "10.7%",
    //     increase: true,
    //     chartLabels: [null, null, null, null, null, null, null],
    //     attrs: { md: "6", sm: "6" },
    //     datasets: [
    //       {
    //         label: "Today",
    //         fill: "start",
    //         borderWidth: 1.5,
    //         backgroundColor: "rgba(0, 184, 216, 0.1)",
    //         borderColor: "rgb(0, 184, 216)",
    //         data: [280,
    //           320,
    //           320,
    //           340,
    //           291,
    //           310,
    //           425
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     label: "최근 7일 판매건수",
    //     value: "182",
    //     percentage: "12.4",
    //     increase: true,
    //     chartLabels: [null, null, null, null, null, null, null],
    //     attrs: { md: "6", sm: "6" },
    //     datasets: [
    //       {
    //         label: "Today",
    //         fill: "start",
    //         borderWidth: 1.5,
    //         backgroundColor: "rgba(23,198,113,0.1)",
    //         borderColor: "rgb(23,198,113)",
    //         data: [280,
    //           220,
    //           320,
    //           340,
    //           491,
    //           410,
    //           525
    //         ]
    //       }
    //     ]
    //   }
    // ]

  }


  render() {

    this.smallStatsData = toJS(this.smallStats);

    return (
      <div className="main-content">
        <PageTitle />

        <Row>
          {this.smallStatsData.map((stats, idx) => {
              return (
                  <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                    <SmallStats
                        id={`small-stats-${idx}`}
                        variation="1"
                        chartData={stats.datasets}
                        chartLabels={stats.chartLabels}
                        label={stats.label}
                        value={stats.value}
                        percentage={stats.percentage}
                        increase={stats.increase}
                    />
                  </Col>
              );
          })}
        </Row>

        <Row>
          {/* Users Overview */}
          <Col lg="8" md="12" sm="12" className="mb-4">
            {this.reDashBoardData.currentMonthReviewCount.length > 0 ?
                <ReviewsOverview reDashBoardData={this.reDashBoardData}/> : null
            }

          </Col>

          {/* Users by Device */}
          <Col lg="4" md="6" sm="12" className="mb-4">
            {this.dashBoardReport && this.dashBoardReport.totlaReviewCount ?
                <ReviewsSentiment totlaReviewCount={this.dashBoardReport.totlaReviewCount} positiveReviewCount={this.dashBoardReport.over60Count} negativeReviewCount={this.dashBoardReport.under40Count} /> : null
            }
          </Col>

          {/* New Draft */}
          {/*<Col lg="4" md="6" sm="12" className="mb-4">*/}
          {/*  <NewDraft />*/}
          {/*</Col>*/}

          {/* Discussions */}
          {/*<Col lg="5" md="12" sm="12" className="mb-4">*/}
          {/*  <Discussions />*/}
          {/*</Col>*/}

          {/* Top Referrals */}
          {/*<Col lg="3" md="12" sm="12" className="mb-4">*/}
          {/*  <TopReferrals />*/}
          {/*</Col>*/}
        </Row>

      </div>
    )
  }
}

export default Dashboard;
