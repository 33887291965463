import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, SignOutLayout } from "./layouts";

// Route containers
import Starter from "./containers/pages/Auth/starter";
import signUp from "./containers/pages/Auth/signUp";
import signIn from "./containers/pages/Auth/signIn";
import Welcome from "./containers/pages/Auth/welcome";
import ServiceAgree from "./containers/pages/Auth/serviceAgree";
import PrivateAgree from "./containers/pages/Auth/privateAgree";

import cafe from "./containers/pages/Auth/cafe";
import cafeInAppOrderReturnUrl from "./containers/pages/Auth/cafeInAppOrderReturnUrl";

import Dashboard from "./containers/pages/Dashboard/dashboard";

import ReviewList from "./containers/pages/Review/reviewList";
import ReviewMileage from "./containers/pages/Review/reviewMileage";
import ReviewWrite from "./containers/pages/Review/reviewWrite";

import ReplyByProductList from "./containers/pages/Review/replyByProductList";
import ReviewGroup from "./containers/pages/Review/reviewGroup";


import ReplyWriteConfig from "./containers/pages/Review/replyWriteConfig";
import ReviewWriteConfig from "./containers/pages/Review/reviewWriteConfig";
import MileageConfig from "./containers/pages/Review/mileageConfig";
import ReviewFilter from "./containers/pages/Review/reviewFilter";

import MetaWidget from "./containers/pages/Widget/metaWidget";
import WidgetList from "./containers/pages/Widget/widgetList";
import productWidgetList from "./containers/pages/Widget/productWidgetList";

import banner from "./containers/pages/Design/banner";

import GoodsList from "./containers/pages/goods/goodsList";

import TeamUserInfoEdit from "./containers/pages/Store/teamUserInfoEdit";
import CompanyInfoEdit from "./containers/pages/Store/companyInfoEdit";
import InAppOrder from "./containers/pages/Store/inAppOrder";
import OrderEnd from "./containers/pages/Store/orderEnd";
import InAppOrderList from "./containers/pages/Store/inAppOrderList";

import StoreList from "./containers/pages/SuperAdmin/storeList";

// Route Views
import Errors from "./views/Errors";



// src/stores/Common.js의 name값 참고
export default [
  {
    path: "/",
    exact: true,
    layout: SignOutLayout,
    component: Starter
  },
  {
    path: "/cafe24",
    layout: SignOutLayout,
    component: cafe
  },
  {
    path: "/cafeRedirect",
    layout: SignOutLayout,
    component: cafe
  },
  {
    path: "/cafeInAppOrderReturnUrl",
    layout: SignOutLayout,
    component: cafeInAppOrderReturnUrl
  },
  {
    path: "/signUp",
    layout: SignOutLayout,
    component: signUp
  },
  {
    path: "/signIn",
    layout: SignOutLayout,
    component: signIn
  },
  {
    path: "/welcome",
    layout: SignOutLayout,
    component: Welcome
  },
  {
    path: "/serviceAgree",
    layout: SignOutLayout,
    component: ServiceAgree
  },
  {
    path: "/privateAgree",
    layout: SignOutLayout,
    component: PrivateAgree
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    info: {mainMenu: 'dashboard'},
    component: Dashboard
  },
  //리뷰
  {
    path: "/reviewList",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewList'},
    component: ReviewList
  },
  {
    path: "/reviewMileage",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewMileage'},
    component: ReviewMileage
  },
  {
    path: "/reviewWrite",
    layout: DefaultLayout,
    info: {mainMenu: 'review', subMenu: 'reviewWrite'},
    component: ReviewWrite
  },
  //설정
  {
    path: "/reviewWriteConfig",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'reviewWriteConfig'},
    component: ReviewWriteConfig
  },

  {
    path: "/mileageConfig",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'mileageConfig'},
    component: MileageConfig
  },
  {
    path: "/reviewGroup",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'reviewGroup'},
    component: ReviewGroup
  },
  {
    path: "/reviewFilter",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'reviewFilter'},
    component: ReviewFilter
  },

  {
    path: "/replyWriteConfig",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'replyWriteConfig'},
    component: ReplyWriteConfig
  },
  {
    path: "/replyByProductList",
    layout: DefaultLayout,
    info: {mainMenu: 'settings', subMenu: 'replyByProductList'},
    component: ReplyByProductList
  },
  //위젯
  {
    path: "/metaWidget",
    layout: DefaultLayout,
    info: {mainMenu: 'widget', subMenu: 'metaWidget'},
    component: MetaWidget
  },
  {
    path: "/widgetList",
    layout: DefaultLayout,
    info: {mainMenu: 'widget', subMenu: 'widgetList'},
    component: WidgetList
  },
  {
    path: "/productWidgetList",
    layout: DefaultLayout,
    info: {mainMenu: 'widget', subMenu: 'productWidgetList'},
    component: productWidgetList
  },
  //디자인
  {
    path: "/banner",
    layout: DefaultLayout,
    info: {mainMenu: 'design', subMenu: 'banner'},
    component: banner
  },
  //상품
  {
    path: "/goodsList",
    layout: DefaultLayout,
    info: {mainMenu: 'goods'},
    component: GoodsList
  },



  //리뷰 작성 설정

  //관리자
  {
    path: "/teamUserInfo",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'teamUserInfo'},
    component: TeamUserInfoEdit
  },
  {
    path: "/companyInfo",
    layout: DefaultLayout,
    info: {mainMenu: 'store', subMenu: 'reviewList'},
    component: CompanyInfoEdit
  },
  //주문
  {
    path: "/inAppOrder",
    layout: DefaultLayout,
    info: {mainMenu: 'payment', subMenu: 'inAppOrder'},
    component: InAppOrder
  },
  {
    path: "/orderEnd",
    layout: DefaultLayout,
    info: {mainMenu: 'payment', subMenu: 'inAppOrder'},
    component: OrderEnd
  },
  {
    path: "/inAppOrderList",
    layout: DefaultLayout,
    info: {mainMenu: 'payment', subMenu: 'inAppOrderList'},
    component: InAppOrderList
  },
  // 슈퍼관리자 메뉴
  {
    path: "/storeList",
    layout: DefaultLayout,
    info: {mainMenu: 'storeList', subMenu: 'storeList'},
    component: StoreList
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
];
