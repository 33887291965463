import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {FormCheckbox, FormGroup} from "shards-react";

@inject(({ teamUser }) => ({ doSignIn: teamUser.doSignIn, doLogOut: teamUser.doLogOut }))
@observer
class SignIn extends Component {

  @observable teamUser = {
    platformId: '',
    emailId: '',
    password: ''
  };

  @observable isSave = false;

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    this.props.doLogOut();
    let storageIsSave= await Storage.get('isSave');
    this.isSave = Boolean(storageIsSave);
    let storageTeamUser = await Storage.getObject('teamUser');
    this.teamUser.platformId = storageTeamUser.platformId || '';
    this.teamUser.emailId = storageTeamUser.emailId || '';
    this.teamUser.password = storageTeamUser.password || '';

    let cafe24Token = await Storage.getObject('cafe24Token');
    this.doLogin = this.doLogin.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  };

  @action
  doLogin() {
    let _this = this;
    let params = {
      platformId : this.teamUser.platformId,
      emailId : this.teamUser.emailId,
      password : this.teamUser.password,
    };

    if(_this.isSave) {
      Storage.setObject('teamUser', params);
    }
    else {
      Storage.remove('teamUser');
    }
    this.props.doSignIn(params, function(res) {
      _this.props.history.push('/reviewList');
    });
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.teamUser[name] = value;
  };

  render() {
    return (
      <>
        <div className="sign-content">
          <div className="sign-content-bg">
            <span className="bg-cont1"></span>
            <span className="bg-cont2"></span>
            <span className="bg-cont3"></span>
            <span className="bg-cont4"></span>
          </div>
          <div className="sign-content-card">
            <h3>로그인</h3>
            <div className="sign-form">
              <dl>
                <dt><input name="platformId" type="text" className="input-id" placeholder="상점 아이디"
                           value={this.teamUser.platformId} onChange={e => this.onChangeValue(e)}/>
                </dt>
              </dl>
              <dl>
                <dt><input name="emailId" type="text" className="input-id" placeholder="이메일 아이디"
                           value={this.teamUser.emailId} onChange={e => this.onChangeValue(e)}/></dt>
              </dl>
              <dl>
                <dt><input name="password" type="password" className="input-password" placeholder="비밀번호"
                           value={this.teamUser.password} onChange={e => this.onChangeValue(e)}/></dt>
              </dl>
              <dl>
                <dt className="is-save">
                  <FormGroup>
                    <FormCheckbox toggle small checked={this.isSave}
                                  onChange={event => {
                                    this.isSave = !this.isSave;
                                    Storage.set('isSave', this.isSave);
                                  }}>저장
                    </FormCheckbox>
                  </FormGroup>
                </dt>
              </dl>
              <dl>
                <dt>
                  <p>1. [상점 아이디].cafe24.com</p>
                  <p>2. 내 쇼핑몰 정보에서 상점 아이디 확인가능합니다.</p>
                  <p>3. 카페24 관리자에서 앱을 실행 하시면 자동로그인 됩니다.</p>
                  <p>* 비밀번호 재설정 : 쇼핑몰 관리자 > 앱 > "온리플" 앱 클릭 하시면<br/>자동로그인이 되며 비밀번호 재설정이 가능합니다.</p></dt>
              </dl>

            </div>
            {/*<p className="guide-text">*초기 비밀번호는 이메일 아이디와 동일합니다.</p>*/}
            <div className="sign-help">
              <div className="sign-auto">
                {/*<input name="" type="checkbox" className="input-sign-auto" />*/}
                {/*<span className="sign-auto-text">자동 로그인</span>*/}
              </div>
              <div className="sign-util">
                {/*<a href="#" className="find-id">아이디 찾기</a>*/}
                {/*<span className="bar"></span>*/}
                {/*<a href="#" className="find-password">비밀번호 찾기</a>*/}
              </div>
            </div>
            <div className="btn-box">
              <input type="button" value="로그인" className="save-button" onClick={event => this.doLogin()}/>
              {/*<Link to={"/signUp"}><span>회원가입</span></Link>*/}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SignIn;
