import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import StartGuide from "../components/layout/StartGuide";
import {ToastContainer} from "react-toastify";

import './Default.scss'
import iconKakao from "../images/btn_kakao.png";

const DefaultLayout = ({ children, route }) => {
  return (
  <div className="wrap">
    <ToastContainer />
    <Sidebar route={route} />
    <div className="contents" route={route}>
      <Header route={route} />
      {children}
      <div className="btn-kakao-ch"><a href="https://pf.kakao.com/_Tsxopxb/chat" target="_blank"><img src={iconKakao} /></a></div>
    </div>
    <StartGuide />
  </div>
)};

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: true
};

export default DefaultLayout;
